/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employer_connection_candidate } from './Employer_connection_candidate';
export type Connection_connection_candidate = {
    readonly uuid: string;
    status: Connection_connection_candidate.status;
    shareStatus: Connection_connection_candidate.shareStatus;
    updatedAt: string;
    employer: Employer_connection_candidate;
};
export namespace Connection_connection_candidate {
    export enum status {
        PROSPECT = 'prospect',
        CONCEPT = 'concept',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELED = 'canceled',
    }
    export enum shareStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
    }
}

